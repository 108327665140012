/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.7.1/dist/jquery.min.js
 * - /npm/jquery-validation@1.19.3/dist/jquery.validate.min.js
 * - /npm/jquery-validation-unobtrusive@3.2.11/dist/jquery.validate.unobtrusive.min.js
 * - /npm/bootstrap@5.3.3/dist/js/bootstrap.bundle.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
